import API from "../axios.service";

const api = new API();
const prefix = "/staging.php";

const sendContact = (formData) => {
  const mainUrl = prefix;

  return api
    .callApi({
      url: mainUrl,
      method: "POST",
      data: formData,
    })
    .then(result => result.data);
};

export default {
  sendContact
}