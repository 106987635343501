import TagManager from "react-gtm-module";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID


export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = ({ eventName, action, category, label, value, globalEmail, globalPhone, crmOrClassic }) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName || "eventGAPlayground",
        category: category,
        action: action,
        label: label,
        globalEmail: globalEmail,
        globalPhone: globalPhone,
        crmOrClassic: crmOrClassic
      },
    });
  }
  catch (e) {
    console.error(e);
  }
};
