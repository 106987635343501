import React, { createContext, useContext, useState, useCallback } from 'react';

const ModalContext = createContext({
  openModals: [],
  openModal: () => { },
  closeModal: () => { },
  hasModalOpen: () => { },
  isModalOpen: false,
  setOpenModals: () => { },
});

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [openModals, setOpenModals] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (modalName) => {
    if (openModals.includes(modalName)) return;
    setOpenModals((prevOpenModals) => [...prevOpenModals, modalName]);
    setIsModalOpen(openModals.length > 0 ? true : false);
  };

  const closeModal = (modalName) => {
    if (!openModals.includes(modalName)) return;
    setOpenModals((prevOpenModals) => prevOpenModals.filter((name) => name !== modalName));
    setIsModalOpen(openModals.length > 0 ? true : false);
  };

  const hasModalOpen = useCallback((modalName) => openModals.includes(modalName), [openModals]);

  return (
    <ModalContext.Provider value={{ openModals, openModal, closeModal, hasModalOpen, isModalOpen, setOpenModals }}>
      {children}
    </ModalContext.Provider>
  );
};
