// context/LocationContext.js
import React, { createContext, useState, useEffect } from "react";

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        console.log("Fetching location data...");
        const response = await fetch('/api/location');
        const data = await response.json();
        console.log("Location data fetched:", data);
        if (data.closestShowroomToUser) {
          setLocation(data.closestShowroomToUser);
          localStorage.setItem('locationFetched', JSON.stringify(data.closestShowroomToUser));
        } else {
          console.error("No closestShowroomToUser in fetched data");
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const storedLocation = localStorage.getItem('locationFetched');
    if (storedLocation) {
      try {
        const parsedLocation = JSON.parse(storedLocation);
        setLocation(parsedLocation);
        console.log("Location data loaded from localStorage:", parsedLocation);
      } catch (error) {
        console.error("Error parsing stored location:", error);
      }
    } else {
      fetchLocation();
    }
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

